import React, { useState } from 'react';

import './App.css';
import Login from './components/Login';
import Posts from './components/Posts';
import PostsEditor from './components/PostsEditor';
import setAnonymousUser from './services/AnonymousUser';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isEditor, setIsEditor] = useState(false);
  const toggleIsEditor = () => {
    if (isAuthenticated) {
      setIsEditor(!isEditor);
    }
  }
  setAnonymousUser()
  return (
    <div
      styles={{

      }}
    >
      <div style={{
        display: "flex",
        backgroundImage: 'url(images/backgrounds/image2.jpg)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
      }} >
        <Login
          setIsAuthenticated={setIsAuthenticated}
        />
        <button
          style={{
            marginLeft: '10px',
            width: '100px',
            display: isAuthenticated ? 'block' : 'none ',
          }}
          onClick={toggleIsEditor}
        >
          {isEditor ? 'Be a Viewer' : 'Be an Editor'}
        </button>
      </div>
      <div
        style={{
          backgroundImage: 'url(images/backgrounds/image6.jpg)',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          paddingTop: '20px',
          paddingBottom: '10px',
        }}
      >
        <div
          style={{
            maxWidth: 'fit-content',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          {isAuthenticated && isEditor ? (
            <PostsEditor />
          ) : (
            <Posts />
          )}
        </div>
      </div>
    </div>
  );
}

export default App;
